@import './base/bootstrap.scss';
@import './base/bootstrap-extended.scss';
@import './base/colors.scss';
@import './base/components.scss';

// Themes
@import './base/themes/dark-layout.scss';
@import './base/themes/bordered-layout.scss';
@import './base/themes/semi-dark-layout.scss';
// @import './base/custom-rtl.scss';

// React Specific
@import 'react/index';

#tiktokPosts {
    rect {
        y:0 !important;
    }
    .apexcharts-xaxis-annotation-label{
        transform:translate(0px,7px) !important;
       
    }
}

.tikTokPosts {
    rect {
        y:0 !important;
    }
    .apexcharts-xaxis-annotation-label{
        transform:translate(0px,7px) !important;
       
    }
}

@media (max-width: 992px) {
    .hide-mobile {
        display: none !important;
    }
    
  }